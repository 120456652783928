/* stylelint-disable color-function-notation */
@use '@angular/material' as mat;
@use 'sass:color';
@use 'sass:map';
@include mat.core;

// Fonts
@import 'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap';
@import 'https://fonts.googleapis.com/css?family=Material+Icons';

/** Application CSS Variables **/
:root {
  /** primary **/
  --sc-color-primary: #00b0b8;
  --sc-color-primary-rgb: 0, 176, 184;
  --sc-color-primary-contrast: #000;
  --sc-color-primary-contrast-rgb: 255, 255, 255;
  --sc-color-primary-shade: #009ba2;
  --sc-color-primary-tint: #1ab8bf;
  --sc-color-primary-background: #4eadb6;

  /** success **/
  --sc-color-success: #42be65;
  --sc-color-success-rgb: 66, 190, 101;
  --sc-color-success-contrast: #000;
  --sc-color-success-contrast-rgb: 0, 0, 0;
  --sc-color-success-shade: #3aa759;
  --sc-color-success-tint: #55c574;

  /** warning **/
  --sc-color-warning: #f1c21b;
  --sc-color-warning-rgb: 241, 194, 27;
  --sc-color-warning-contrast: #000;
  --sc-color-warning-contrast-rgb: 0, 0, 0;
  --sc-color-warning-shade: #d4ab18;
  --sc-color-warning-tint: #f2c832;

  /** danger **/
  --sc-color-danger: #ff8389;
  --sc-color-danger-rgb: 255, 131, 137;
  --sc-color-danger-contrast: #000;
  --sc-color-danger-contrast-rgb: 0, 0, 0;
  --sc-color-danger-shade: #e07379;
  --sc-color-danger-tint: #ff8f95;

  /** dark **/
  --sc-color-dark: #262626;
  --sc-color-dark-rgb: 38, 38, 38;
  --sc-color-dark-contrast: #fff;
  --sc-color-dark-background: #15343b;
  --sc-color-dark-contrast-rgb: 255, 255, 255;
  --sc-color-dark-shade: #0d0d0d; // Carbon ui-background
  --sc-color-dark-tint: #393939; // Carbon ui-01
  --sc-background-item: #272b2f;
  --sc-header-background: #0e1013;

  /** medium **/
  --sc-color-medium: #6f6f6f; // Carbon buttons
  --sc-color-medium-rgb: 111, 111, 111;
  --sc-color-medium-contrast: #fff;
  --sc-color-medium-contrast-rgb: 255, 255, 255;
  --sc-color-medium-shade: #525252; // Carbon ui-02
  --sc-color-medium-tint: #8d8d8d; // Carbon borders

  /** light **/
  --sc-color-light: #f4f4f4;
  --sc-color-light-rgb: 244, 244, 244;
  --sc-color-light-contrast: #000;
  --sc-color-light-contrast-rgb: 0, 0, 0;
  --sc-color-light-shade: #c6c6c6;
  --sc-color-light-tint: #f5f5f5;
  --sc-color-light-muted: #d3d3d3;
}

/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

$font-config: (
  display-4: mat.m2-define-typography-level(112px, 112px, 300, 'Ubuntu', -0.0134em),
  display-3: mat.m2-define-typography-level(56px, 56px, 400, 'Ubuntu', -0.0089em),
  display-2: mat.m2-define-typography-level(45px, 48px, 400, 'Ubuntu', 0),
  display-1: mat.m2-define-typography-level(34px, 40px, 400, 'Ubuntu', 0.0074em),
  headline: mat.m2-define-typography-level(24px, 32px, 400, 'Ubuntu', 0),
  title: mat.m2-define-typography-level(20px, 32px, 500, 'Ubuntu', 0.0075em),
  subheading-2: mat.m2-define-typography-level(16px, 28px, 400, 'Ubuntu', 0.0094em),
  subheading-1: mat.m2-define-typography-level(15px, 24px, 500, 'Ubuntu', 0.0067em),
  body-2: mat.m2-define-typography-level(14px, 24px, 500, 'Ubuntu', 0.0179em),
  body-1: mat.m2-define-typography-level(14px, 20px, 400, 'Ubuntu', 0.0179em),
  button: mat.m2-define-typography-level(14px, 14px, 500, 'Ubuntu', 0.0893em),
  caption: mat.m2-define-typography-level(12px, 20px, 400, 'Ubuntu', 0.0333em),
  input: mat.m2-define-typography-level(inherit, 1.125, 400, 'Ubuntu', 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-text: #1c1c1c;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

// Dark Theme text
$light-text: #f4f4f4;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

// Background config
// Light bg
$light-background: #f4f4f4;
$light-bg-darker-5: color.adjust($light-background, $lightness: -5%);
$light-bg-darker-10: color.adjust($light-background, $lightness: -10%);
$light-bg-darker-20: color.adjust($light-background, $lightness: -20%);
$light-bg-darker-30: color.adjust($light-background, $lightness: -30%);
$light-bg-lighter-5: color.adjust($light-background, $lightness: 5%);
$dark-bg-tooltip: color.adjust(#1c1c1c, $lightness: 20%);
$dark-bg-alpha-4: rgb(#1c1c1c, 0.04);
$dark-bg-alpha-12: rgb(#1c1c1c, 0.12);

// Compute font config
@include mat.all-component-typographies($font-config);

// Theme Config
body {
  --primary-color: #00b0b8;
  --primary-lighter-color: #b3e7ea;
  --primary-darker-color: #0097a0;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
  --accent-color: #393939;
  --accent-lighter-color: #c4c4c4;
  --accent-darker-color: #242424;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
  --warn-color: #ff8389;
  --warn-lighter-color: #ffdadc;
  --warn-darker-color: #ff666c;
  --text-warn-color: #{$dark-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$dark-primary-text};
}

$mat-primary: (
  main: #00b0b8,
  lighter: #b3e7ea,
  darker: #0097a0,
  200: #00b0b8,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$mat-accent: (
  main: #393939,
  lighter: #c4c4c4,
  darker: #242424,
  200: #393939,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$mat-warn: (
  main: #ff8389,
  lighter: #ffdadc,
  darker: #ff666c,
  200: #ff8389,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);
$sc-theme: mat.m2-define-dark-theme(
  (
    color: (
      theme-type: dark,
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family: 'Ubuntu, sans-serif',
      ),
  )
);

// Theme Init
@include mat.all-component-themes($sc-theme);

html {
  color: var(--sc-color-light);
  --mat-table-row-item-label-text-color: var(--sc-color-light-shade);

  // Slide toggle colors
  --mdc-switch-selected-focus-state-layer-color: var(--sc-color-primary);
  --mdc-switch-selected-handle-color: var(--sc-color-primary);
  --mdc-switch-selected-hover-state-layer-color: var(--sc-color-primary-background);
  --mdc-switch-selected-pressed-state-layer-color: var(--sc-color-primary-background);
  --mdc-switch-selected-focus-handle-color: var(--sc-color-primary-background);
  --mdc-switch-selected-hover-handle-color: var(--sc-color-primary-background);
  --mdc-switch-selected-pressed-handle-color: var(--sc-color-primary-background);
  --mdc-switch-selected-focus-track-color: var(--sc-color-primary-background);
  --mdc-switch-selected-hover-track-color: var(--sc-color-primary-background);
  --mdc-switch-selected-pressed-track-color: var(--sc-color-primary-background);
  --mdc-switch-selected-track-color: var(--sc-color-primary-background);

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-disabled-selected-icon-color: white;
    --mdc-radio-disabled-unselected-icon-color: white;
    --mdc-radio-unselected-hover-icon-color: #eee;
    --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 54%);
    --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 54%);
    --mdc-radio-selected-focus-icon-color: var(--sc-color-primary);
    --mdc-radio-selected-hover-icon-color: var(--sc-color-primary);
    --mdc-radio-selected-icon-color: var(--sc-color-primary);
    --mdc-radio-selected-pressed-icon-color: var(--sc-color-primary);
    --mat-radio-ripple-color: white;
    --mat-radio-checked-ripple-color: var(--sc-color-primary);
    --mat-radio-disabled-label-color: rgba(255, 255, 255, 50%);
    --mat-radio-label-text-color: white;
  }

  .mat-mdc-raised-button:not(:disabled) {
    background-color: var(--sc-color-primary);
    color: var(--sc-color-dark);
  }

  .mat-mdc-dialog-container .mat-mdc-dialog-content {
    color: var(--sc-color-light);
    padding: 0;
  }

  .mat-mdc-table {
    background-color: var(--sc-color-dark);
  }

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0;
  }

  .mdc-line-ripple {
    display: none;
  }
}

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: 'Material Icons';

  .mat-badge-content {
    font-family: Ubuntu, sans-serif;
  }
}
