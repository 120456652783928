.sc-no-shadow {
  --box-shadow: none;

  box-shadow: none;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-error {
  color: var(--sc-color-danger-contrast) !important;
  padding-left: 0.3rem;
  background-color: var(--sc-color-danger);
}

.vertical-header {
  transform: rotate(-10deg);
}

.border-bottom {
  border-bottom: 1px solid var(--sc-color-dark);
}

.fullheight {
  height: 100%;
}

.fullwidth {
  width: 100%;
}

.relative {
  position: relative;
}

.bold {
  font-weight: 600;
}

.hidden {
  display: none;
}

.dragover-active {
  --background: var(--sc-color-medium);

  background: var(--sc-color-medium);
  border-style: dashed solid;
}

.cursor-pointer {
  cursor: pointer;
}

.color-danger {
  color: var(--sc-color-danger) !important;
}

.color-medium {
  color: var(--sc-color-medium) !important;
}

.color-light {
  color: var(--sc-color-light) !important;
}

.scroll-column {
  overflow: hidden scroll;
  max-height: 100%;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--sc-color-dark);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--sc-color-dark);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--sc-color-gray);
  }

  .list-item {
    &.active {
      --background: var(--sc-color-dark) !important;
    }
  }
}

.voyage-info {
  display: grid;
  grid-template-columns: 0.4fr 0.3fr 0.3fr 0.3fr 0.5fr 0.4fr;
  grid-row-gap: 1rem;

  @media screen and (width <= 1728px) {
    h4 {
      font-size: 16px !important;
    }
  }

  @media screen and (width <= 1440px) {
    h4 {
      font-size: 14px !important;
    }
  }

  p {
    color: var(--sc-color-medium);
    font-family: Ubuntu;
    margin-bottom: 0;
    margin-top: 0.3rem;
  }

  div {
    min-width: 50px;
    font-weight: 600;

    &:nth-child(1) {
      word-wrap: break-word;
      margin-right: 10px;
      line-height: 2rem;
    }

    .charter-info {
      display: flex;
      flex-direction: column;
    }
  }

  h4 {
    font-size: 1rem;
    font-weight: 600;
    text-overflow: ellipsis;
    height: 25px;
    line-height: normal;
    letter-spacing: 0.16px;

    pre {
      font-weight: 400;
      font-size: 0.8rem;
    }
  }

  @media screen and (width >= 2000px) {
    div {
      min-width: 200px;
    }
  }
}

/* ----- [Tooltips] ----- */
.sc-menu-tooltip {
  height: 48px;
  color: var(--sc-color-light) !important;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0 !important;
  margin-left: -6px !important;
  padding: 0 0.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-menu-tooltip.active {
  background: var(--sc-color-dark-shade);
  color: var(--sc-color-dark-shade) !important;
}