.marker {
  background-size: cover;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;

  .marker-tooltip {
    position: absolute;
    transform: translateY(-50px) translateX(-24px);
    display: none;
    min-width: 80px;
    background: var(--sc-color-dark);
    color: var(--sc-color-warning);
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    z-index: 1000000;

    * {
      margin: 0;
    }
  }

  .marker-tooltip-static {
    position: absolute;
    transform: translateY(-36px) translateX(-24px);
    display: flex;
    min-width: 80px;
    background: var(--sc-color-dark);
    color: var(--sc-color-warning);
    padding: 4px;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
    white-space: nowrap;
    border-radius: 4px;

    * {
      margin: 0;
    }
  }

  &:hover .marker-tooltip {
    display: flex;
  }
}

.blue {
  background-size: cover;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;

  .blue-tooltip-static {
    display: none;
  }

  &:hover .blue-tooltip-static {
    position: absolute;
    transform: translateY(-36px) translateX(-24px);
    display: flex;
    min-width: 80px;
    background: var(--sc-color-dark);
    color: #00b0b8;
    padding: 4px;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
    white-space: nowrap;
    border-radius: 4px;

    * {
      margin: 0;
    }
  }
}

.mapboxgl-popup-content {
  background-color: rgb(29 33 37 / 90%) !important;
  border-radius: 5px;
  padding: 16px 10px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
  font-family: Arial, sans-serif;
  z-index: 1;
  display: flex;
}

.mapboxgl-popup-close-button {
  color: #fff;
  border: none;
  outline: none;
}

.mapboxgl-popup-tip {
  border-bottom-color: #1d2125;
}

.mapboxgl-popup-tip::after {
  border-bottom-color: #1d2125;
}

.mapboxgl-popup.mapboxgl-popup-anchor-bottom {
  max-width: unset !important;
  transform: translate(-50%, -150%) translate(500px, 425px) !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  align-self: center;
  align-self: center;
  border-bottom: none;
  border-top-color: #1d2125;
}

.popup-columns {
  display: flex;
}

.left-column {
  flex: 1;
  padding: 0 5px;
}

.right-column {
  padding: 0 5px;
  text-align: left;
}

.sc-report-marker {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}
