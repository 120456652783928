/* Optional CSS utils that can be commented out */
@import 'theme';
@import 'containers';
@import 'helpers';
@import 'map';
@import 'material';

body {
  background-color: var(--sc-color-dark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  text-rendering: optimizelegibility;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  word-wrap: break-word;
  overscroll-behavior-y: none;
  text-size-adjust: none;
  text-size-adjust: none;
  font-family: Ubuntu, sans-serif;
  color: var(--sc-color-light);
}

pre {
  line-height: 1.3rem;
  font-family: inherit;
}

a {
  color: var(--sc-color-primary);

  &:hover {
    color: var(--sc-color-primary);
  }
}

/* Scrollbar width */
::-webkit-scrollbar {
  width: 10px;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: var(--sc-color-dark);
}

/* Scrollbar handle */
::-webkit-scrollbar-thumb {
  background: var(--sc-color-dark);
}

/* Scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--sc-color-gray);
}

/* Hide input arrows: Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Hide input arrows: Firefox */
input[type='number'] {
  appearance: textfield;
}

textarea {
  min-height: 50px;
  width: 100%;
  border-radius: 4px;
  padding: 16px;
  border: 1px solid var(--sc-background-item);
  background: var(--sc-background-item);
  box-shadow: inset 2px 2px 5px rgb(0 0 0 / 35%), inset -2px -2px 5px rgb(255 255 255 / 10%);
  color: var(--sc-color-light);
  margin: 6px 0;
}

textarea:focus {
  border: 2px solid var(--sc-color-primary-200);
  box-shadow: 0 0 6px rgb(181 255 255 / 30%), inset 2px 2px 5px rgb(0 0 0 / 35%),
    inset -2px -2px 5px rgb(255 255 255 / 10%);
  outline: none;
}

.text-underline {
  text-decoration: underline;
}

.imported-reports-modal {
  --min-width: 80%;
  --min-height: 800px;

  @media screen and (width <= 1810px) {
    --min-width: 90%;
  }

  @media screen and (width <= 1610px) {
    --min-width: 100%;
    --min-height: 100%;
  }

  @media screen and (width <= 1440px) {
    --min-width: 100%;
    --min-height: 100%;
  }
}

// Invalid input warning card
.warning-card {
  padding: 1rem;
  background-color: var(--sc-color-dark-tint);
  border: 1px solid rgb(253 209 58 / 50%);
  box-sizing: border-box;
  border-left: 3px solid var(--sc-color-warning);
  border-radius: 0;
}

// Invalid input error card
.error-card {
  padding: 1rem;
  background-color: var(--sc-color-light);
  box-sizing: border-box;
  border-left: 3px solid #da1e28;
  border-radius: 0;
}

// ANIMATIONS 
//Loading shimmer
.loading {
  animation: loading 1.1s infinite linear;
  background: var(--sc-color-medium-shade);
  background-image: linear-gradient(to right, var(--sc-color-medium-shade) 0%, var(--sc-color-medium) 20%, var(--sc-color-medium-shade) 40%, var(--sc-color-medium-shade) 100%);
  background-image: linear-gradient(to right, var(--sc-color-medium-shade) 0%, var(--sc-color-medium) 20%, var(--sc-color-medium-shade) 40%, var(--sc-color-medium-shade) 100%);
  background-repeat: no-repeat;
  min-height: 15px;
  height: 100%;
  width: 100%;

  >* {
    display: none;
  }
}

@keyframes loading {
  0% {
    background-position: -100px;
  }

  100% {
    background-position: 200px;
  }
}

// circular spinner
.sc-spinner {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.sc-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 3px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: sc-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--sc-color-primary) transparent transparent transparent;
}

.sc-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.sc-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.sc-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes sc-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}