button {
  border: 0;

  .default {
    height: 52px;
  }

  &.mat-primary,
  &.sc-bg-danger {
    width: 250px;
    min-height: 45px;
    border-radius: 8px;
    padding: auto 16px;

    &.mat-raised-button {
      .mat-button-wrapper {
        color: var(--sc-color-dark);
      }
    }

    &:focus {
      opacity: 0.7;
    }
  }

  &.btn-clear {
    border: none;
  }

  &.action-btn {
    font-size: 18px;
    cursor: pointer;
    font-weight: 400;
    text-align: center;
    padding: 0;
    width: auto;
  }

  &.no-padding {
    padding: 0;
    width: auto;
  }
}

.flex {
  display: flex;
  width: 100%;

  &.start {
    justify-content: flex-start;
  }

  &.column {
    flex-direction: column;
  }

  &.between {
    justify-content: space-between;
  }

  &.center {
    align-items: center;
  }

  &.end {
    justify-content: flex-end;
  }
}

.grid {
  display: grid;
  width: 100%;
  position: relative;
  grid-gap: 1rem;

  &.two-columns {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(1, 1fr);
    gap: 0 0.5rem;
  }

  &.three-columns {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(1, 1fr);
    gap: 0 0.5rem;
  }

  &.gapless {
    gap: 0;

    > * {
      width: 100%;
    }
  }
}

.sc-no-margin {
  margin: 0;
}

.sc-no-padding {
  padding: 0;
}

.sc-margin {
  margin: 16px;
}

.sc-padding {
  padding: 16px;
}

.sc-margin-top-auto {
  margin-top: auto;
}

.sc-margin-horizontal {
  margin-left: 16px;
  margin-right: 16px;
}

.sc-margin-horizontal-sm {
  margin-left: 8px;
  margin-right: 8px;
}

.sc-margin-vertical {
  margin-top: 16px;
  margin-bottom: 16px;
}

.sc-margin-right-sm {
  margin-right: 8px;
}

.sc-margin-left-lg {
  margin-left: 12px;
}

.sc-padding-horizontal {
  padding-left: 16px;
  padding-right: 16px;
}

.sc-padding-vertical {
  padding-top: 16px;
  padding-bottom: 16px;
}

.sc-padding-end {
  padding-right: 16px;
}

.sc-padding-top {
  padding-top: 16px;
}

.sc-sm-padding-top {
  padding-top: 8px;
}

.sc-padding-bottom {
  padding-bottom: 16px;
}

.sc-lg-padding-bottom {
  padding-bottom: 48px;
}

.sc-margin-top {
  margin-top: 16px;
}

.sc-lg-margin-top {
  margin-top: 48px;
}

.sc-sm-margin-bottom {
  margin-bottom: 8px;
}

.sc-margin-bottom {
  margin-bottom: 16px;
}

.h-100 {
  height: 100%;
}

.sc-text-lght-grey {
  color: var(--sc-color-danger);
}

.search-bar {
  height: 45px;
  display: flex;
  padding-left: 10px;
  border-radius: 6px;
  background-color: var(--sc-color-dark);
  border: 2px solid var(--sc-color-dark-tint);

  mat-icon {
    align-self: center;
    color: var(--sc-color-light);
    margin-right: 10px;
  }

  &.tint {
    background-color: var(--sc-color-dark-tint);
    width: 550px;
  }

  .search-field {
    width: 500px;
    height: 100%;
    background-color: var(--sc-color-dark);
    color: var(--sc-color-light);
    outline: none;
    border: none;

    &.tint {
      background-color: var(--sc-color-dark-tint);
    }
  }

  @media screen and (width: 2000px) {
    height: 35px;
  }
}

.empty-state {
  background: var(--sc-color-dark);
  color: var(--sc-color-medium);
  position: absolute;
  top: 250px;
  left: 39px;
  z-index: 100;
  max-height: 428px;
  width: 500px;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.sc-card {
  border-radius: 0;
  box-shadow: none;
  background: var(--sc-color-dark-tint);
}

.sc-text-danger {
  color: var(--sc-color-danger);
}

.sc-text-warning {
  color: var(--sc-color-warning);
}

.sc-text-primary {
  color: var(--sc-color-primary);
}

.sc-text-success {
  color: var(--sc-color-success);
}

.sc-text-light {
  color: var(--sc-color-light);
}

.sc-text-mute {
  color: var(--sc-color-light-muted);
}

.sc-bg-danger {
  background-color: var(--sc-color-danger) !important;
  color: var(--sc-color-dark);
}

.sc-bg-warning {
  background-color: var(--sc-color-warning);
}

.sc-bg-primary {
  background-color: var(--sc-color-primary);
}

.sc-dashed-border-warning {
  border: 1px dashed var(--sc-color-warning);
}

.sc-center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sc-flex-container {
  display: flex;

  .sc-same-height {
    flex: 1;
  }
}

.sc-note {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.16px;
}

.sc-small-text {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.sc-icon {
  margin: auto 0;
  cursor: pointer;
}

.sc-dialog {
  .sc-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--sc-color-dark);
    padding: 10px;
  }
}

.sc-no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 20%;
  margin-top: 10%;
  padding-bottom: 10%;
  font-family: Ubuntu;

  .mat-icon {
    width: 96px;
    height: 56px !important;
    font-size: 56px;
  }

  .texts {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.16px;
    color: var(--sc-color-light);
  }

  .retry-texts {
    color: #f4f4f4;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
}

.report-qa {
  margin-top: 4px;
  min-width: 145px;
  display: flex;
  cursor: pointer;

  mat-icon {
    font-size: 18px;
    margin-top: 5px;
  }

  h6 {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0;
  }

  div {
    font-size: 11px;
  }
}

.sc-tooltip-container {
  .sc-tooltip {
    width: 250px;
    max-width: 400px;
    padding-left: 8px;
    padding-right: 4px;
    top: -20px;
    left: 30%;
    transform: translate(-100%, -70%);
    color: var(--sc-color-light);
    background-color: var(--sc-color-dark);
    font-weight: normal;
    font-size: 10px;
    border-radius: 8px;
    position: absolute;
    z-index: 1000;
    box-sizing: border-box;
    box-shadow: 0 1px 8px var(--sc-color-dark);
    display: none;

    h6 {
      margin: 16px 0;
    }

    ul {
      margin-top: -10px;
      margin-left: -15px;
    }

    i {
      position: absolute;
      top: 100%;
      left: 70%;
      margin-left: -15px;
      width: 30px;
      height: 15px;
      overflow: hidden;
    }

    i::after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      left: 10%;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: var(--sc-color-dark);
      box-shadow: 0 1px 8px var(--sc-color-dark);
    }

    &.right {
      top: 30%;
      left: 45%;
      margin-left: 20px;
      transform: translate(0, -50%);
      font-size: 12px;

      i {
        position: absolute;
        top: 70%;
        left: 1%;
        margin-top: -12px;
        width: 12px;
        height: 24px;
      }

      i::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 0;
        top: 70%;
        transform: translate(50%, -50%) rotate(-45deg);
        background-color: var(--sc-color-dark);
        box-shadow: 0 1px 8px var(--sc-color-dark);
      }
    }

    &.right-alt {
      top: 30%;
      left: 20%;
      margin-left: 20px;
      transform: translate(0, -50%);
      font-size: 12px;

      i {
        position: absolute;
        top: 70%;
        left: 1%;
        margin-top: -12px;
        width: 12px;
        height: 24px;
      }

      i::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 0;
        top: 70%;
        transform: translate(50%, -50%) rotate(-45deg);
        background-color: var(--sc-color-dark);
        box-shadow: 0 1px 8px var(--sc-color-dark);
      }
    }
  }

  &:hover {
    .sc-tooltip {
      display: block;
    }
  }
}

.sc-card {
  .internal-comment {
    .sc-card {
      padding: 0;
    }
  }
}

.mat-tab-label.mat-tab-label-active {
  opacity: 1;
  .mat-tab-label-content {
    color: var(--sc-color-primary);
  }
}

.sc-chip {
  &.not-started {
    background-color: var(--sc-color-medium) !important;
    --mdc-chip-label-text-color: var(--sc-color-dark);
    border: none;
  }

  &.assumed-started {
    background: transparent;
    --mdc-chip-label-text-color: var(--sc-color-light);
    border: 1px dashed var(--sc-color-light);
  }

  &.underway {
    background: transparent !important;
    --mdc-chip-label-text-color: var(--sc-color-light);
    border: 1px solid var(--sc-color-light);
  }

  &.assumed-ended {
    background: var(--sc-color-light) !important;
    --mdc-chip-label-text-color: var(--sc-color-dark);
    border: none;
  }

  &.completed {
    background: transparent !important;
    --mdc-chip-label-text-color: var(--sc-color-success);
    border: 1px dashed var(--sc-color-success);
  }

  &.ready-to-verify {
    background: transparent;
    color: var(--sc-color-success);
    border: 1px solid var(--sc-color-success);
  }

  &.verified {
    background: var(--sc-color-success) !important;
    --mdc-chip-label-text-color: var(--sc-color-dark);
    border: none;
  }

  &.cancelled {
    background: var(--sc-color-warning);
    color: var(--sc-color-dark);
    border: none;
  }

  &.default {
    background: transparent;
    color: var(--sc-color-light);
    border: none;
  }
}

