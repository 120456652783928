.mat-cell:last-of-type,
.mat-header-cell:last-of-type {
  justify-content: flex-end;
}

.mat-header-cell {
  color: var(--sc-color-light);
  background: var(--sc-color-dark-tint);
  font-size: 14px;
}

.mat-cell {
  color: var(--sc-color-light-shade);
}

.mat-expansion-panel {
  background: var(--sc-color-dark-tint) !important;
  box-shadow: none !important;
  color: var(--sc-color-light);
  border-radius: 0 !important;

  .mat-expansion-panel-header {
    .mat-expansion-panel-header-title {
      color: var(--sc-color-light);
      font-size: 1.2rem;
      font-weight: 400;
    }

    .mat-expansion-panel-header-description {
      flex-grow: 100;
      color: #b5b5b5;
      line-height: 1.5rem;
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
  background: var(--sc-color-dark);
}

.mat-autocomplete {
  background: var(--sc-color-medium-shade);
  border-radius: 0;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: var(--sc-color-medium-tint);
}

mat-icon.svg {
  transform: scale(0.8);
  align-self: center;
  cursor: pointer;
}

// remove hover background
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  background-color: transparent;
}

mat-radio-group.mdc-radio-group.sc-radio-group {
  display: grid;
  margin: 10px 5px;

  &.radio-column {
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 5px;
  }

  &.radio-column-sm {
    grid-template-columns: auto auto auto;
    font-size: 0.8rem;
    grid-gap: 5px;
  }

  .mat-mdc-radio-button.mat-accent {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

mat-select {
  &.sc-select {
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    padding: 0 6px;
    border: 1px solid var(--sc-background-item);
    background: var(--sc-background-item);
    box-shadow:
      inset 2px 2px 5px rgb(0 0 0 / 35%),
      inset -2px -2px 5px rgb(255 255 255 / 10%);
    margin: 6px 0;
    cursor: pointer;
  }

  &.sc-select-secondary {
    height: 35px;
    line-height: 35px;
    padding: 0 6px;
    border: 1px solid var(--sc-color-dark-tint);
    background: var(--sc-color-dark-tint);
    cursor: pointer;

    .mat-select-placeholder {
      color: #6f6f6f;
      font-size: 12px;
    }

    @media screen and (width >= 2000px) {
      height: 40px;
      line-height: 40px;
    }
  }
}

.mat-drawer-backdrop.mat-drawer-shown {
  background: transparent;
}

// mat-paginator
mat-paginator {
  background: var(--sc-color-dark) !important;

  .mdc-notched-outline > * {
    border: none !important;
  }
}

mat-datepicker-content.mat-datepicker-content {
  margin-top: 14px;
}

mat-option {
  font-size: 14px !important;

  .mat-option-text {
    border: none !important;
    min-width: 200px;
  }

  .mat-pseudo-checkbox {
    display: none;
  }
}

.mat-mdc-select-panel {
  max-height: 400px;
  min-width: 200px;
}

.mat-menu-item {
  font-size: 14px;
  font-weight: 400;
}